import React from 'react';
import './tailwind.output.css';
import './App.css';
import PlayerView from "./views/PlayerView";
import {SWRConfig} from 'swr'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";


function App() {


    return (
        <SWRConfig value={{
            refreshInterval: 30000,
        }}>
            <div className="background-image">
                <div className="background-overlay">
                </div>
                <Router>
                    <Switch>
                        <Route exact path="/embeddable*">
                            <PlayerView embedded={true}/>

                        </Route>
                        <Route path="*">
                            <PlayerView embedded={false}/>
                            <footer className="bg-white p-4 mobile:text-xs">This site uses <a
                                className="underline hover:text-blue-500"
                                href="https://www.freyrbattery.com/cookies">cookies</a></footer>
                        </Route>
                    </Switch>
                </Router>
            </div>

        </SWRConfig>
    );
}

export default App;
