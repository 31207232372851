import {apiBaseUrl} from "../constants";

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export type FetchStatus = "idle" | "pending" | "resolved" | "rejected";

export const API_URL = process.env.REACT_APP_API_URL || apiBaseUrl;

const fetcher = async <T>(request: Request): Promise<T> => {
    const token = window.sessionStorage.getItem("AUTH_TOKEN");

    if (token) {
        request.headers.append("Authorization", "Bearer " + token);
    }

    return fetch(request).then<Promise<T>>((res) => {
        if (!res.ok) {
            return res.json().then((body) => {
                return Promise.reject({
                    status: res.status,
                    ok: false,
                    body,
                });
            });
        }

        return res.json();
    });
};

export const httpGet = <T>(url: string, args: RequestInit = { method: Method.GET }): Promise<T> => {
    const urlEnding = url.includes("?") ? "" : "/";
    return fetcher<T>(new Request(API_URL + url + urlEnding, args));
};

export const httpPost = <T>(
    url: string,
    body?: string,
    args: RequestInit = {
        method: Method.POST,
        headers: {
            "Content-Type": "application/json",
        },
        body,
    }
) => {
    return fetcher<T>(new Request(API_URL + url + "/", args));
};

export const httpPut = <T>(
    url: string,
    body?: string,
    args: RequestInit = {
        method: Method.PUT,
        headers: {
            "Content-Type": "application/json",
        },
        body,
    }
) => {
    return fetcher<T>(new Request(API_URL + url + "/", args));
};

export const httpDelete = <T>(
    url: string,
    body?: string,
    args: RequestInit = {
        method: Method.DELETE,
        headers: {
            "Content-Type": "application/json",
        },
        body,
    }
) => {
    return fetcher<T>(new Request(API_URL + url + "/", args));
};
