import React, {useContext, useState} from "react";
import {AuthContext, httpGet, httpPost} from "../data";
import useSWR from 'swr';
import {Event, User} from "../types"
import "./event-page.scss";
// @ts-ignore
import ReactJWPlayer from "react-jw-player";
import {v4 as uuidv4} from "uuid";


interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}


interface Props {
    event: Event
    userId: string
    accessToken: string | undefined
    slug: string
    embedded?: boolean
    children?: JSX.Element
}

const HeaderAndStaffache = ({children, event, userId, accessToken, embedded, slug} : Props) => {

    return(<>
        <div className=
                 {event.text !== "vislogo" ?
                     "center-container   py-4 min-h-screen transition-component" :
                     "center-container   py-4 min-h-screen transition-component"
                 }
        >

            <header className="mb-6 p-6 pl-10 ml-2 relative mobile:ml-6 mobile:p-0">
                <img src="/graphics/logo/freyrlogotaglinenegative.png" className=
                    "mobile:p-4 desktop:w-1/6 extraLargeDesktop:w-1/12 mobile:w-1/2"/>
            </header>
            {children}

        </div>
    </>)
}


interface Props {
    event: Event
    userId: string
    accessToken: string | undefined
    slug: string
    embedded?: boolean
    children?: JSX.Element
}

const VideoAndChatView = ({event, userId, accessToken, slug, embedded, children}: Props) => {

    if (embedded) {
        return (
            <main className={`relative`}>

                {event.text && <div className="p-3 text-lg w-full z-30 absolute text-center text-white bg-blue-400">{event.text}</div>}


                {event.userHasAccess && event.displayVideo && (
                    <div className="player w-full mx-auto rounded relative">
                        <ReactJWPlayer
                            playerId="jwplayer"
                            customProps={{repeat: true, autoplay: false, autoStart: false, autostart: false, displaytitle: true, title: event.subtitle, mute: false}}
                            playerScript="https://cdn.jwplayer.com/libraries/69PLM1uc.js"
                            file={event.hlsUrl}
                            image={event.posterUrl}
                            title={event.title}
                            preload={'auto'}

                        />
                    </div>
                )}

                {event.userHasAccess && !event.displayVideo && (
                    <div
                        className=" w-full mobile:w-full mx-auto player w-full rounded relative bg-black">
                        <div className="bg-poster aspect-ratio-169 absolute top-0 left-0 w-full">
                        </div>
                        <div className="aspect-ratio-169  shadow-lg rounded ">
                            <div
                                className="mx-auto w-full text-center text-white text-3xl mobile:text-xl absolute center-holder">

                                <div className="">{event.subtitle}</div>
                            </div>
                        </div>
                    </div>
                )}

                {!event.userHasAccess && (
                    <div
                        className=" w-full mx-auto player w-full rounded relative bg-black">
                        <div className="bg-poster aspect-ratio-169 absolute top-0 left-0 w-full">
                        </div>
                        <div className="aspect-ratio-169  shadow-lg rounded ">
                            <div
                                className="mx-auto w-full text-center text-white text-3xl mobile:text-xl absolute center-holder">

                                <div className="">{event.subtitle}</div>
                            </div>
                        </div>
                    </div>
                )}



            </main>
        )
    }


    return (
        <HeaderAndStaffache event={event} userId={userId} accessToken={accessToken} slug={slug}>
            <main className={`event-page desktop:p-8 mobile:p-4`}>
                {event.userHasAccess && event.displayVideo && (
                    <div className="event-page__player player w-full mx-auto desktop:w-4/5 rounded relative">
                        <ReactJWPlayer
                            playerId="jwplayer"
                            customProps={{repeat: true, autoplay: false, autoStart: false, autostart: false, displaytitle: true, title: event.subtitle, mute: false}}
                            playerScript="https://cdn.jwplayer.com/libraries/69PLM1uc.js"
                            file={event.hlsUrl}
                            image={event.posterUrl}
                            title={event.title}
                            preload={'auto'}

                        />
                    </div>
                )}

                {event.userHasAccess && !event.displayVideo && (
                    <div
                        className="event-page__player w-full desktop:w-4/5 mobile:w-full mx-auto player w-full rounded relative bg-black">
                        <div className="bg-poster aspect-ratio-169 absolute top-0 left-0 w-full">
                        </div>
                        <div className="aspect-ratio-169  shadow-lg rounded ">
                            <div
                                className="mx-auto w-full text-center text-white text-3xl mobile:text-xl absolute center-holder">

                                <div className="">{event.subtitle}</div>
                            </div>
                        </div>
                    </div>
                )}

                {!event.userHasAccess && (
                    <div
                        className="event-page__player w-full desktop:w-4/5 mobile:w-full mx-auto player w-full rounded relative bg-black">
                        <div className="bg-poster aspect-ratio-169 absolute top-0 left-0 w-full">
                        </div>
                        <div className="aspect-ratio-169  shadow-lg rounded ">
                            <div
                                className="mx-auto w-full text-center text-white text-3xl mobile:text-xl absolute center-holder">

                                <div className="">{event.subtitle}</div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="event-page__content mx-auto w-full text-center text-white text-xl mobile:text-lg mt-8">{event.text}</div>


            </main>
        </HeaderAndStaffache>
    )
}

export default ({embedded}: {embedded: boolean}) => {

    const [userId] = useState(uuidv4());

    const slug = "freyr"

    const {accessToken} = useContext(AuthContext);
    const {data: event, mutate, revalidate} = useSWR<Event>(
        "events/slug/" + slug,
        httpGet
    );

    if (!event) {
        return (
            <div className="center-container w-full h-screen">
                <div className="center-holder text-white">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white my-auto"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#fff"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="#fff"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>

                </div>
            </div>
        );
    }

    return (<VideoAndChatView event={event} userId={userId} accessToken={accessToken} slug={slug} embedded={embedded}/>)


}
