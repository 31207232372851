import React, {useEffect, useMemo, useState} from "react";
import jwt from "jsonwebtoken";
import {httpGet, httpPost} from "./fetcher";
import {AccessTokenData, User} from "../types";

interface AuthContext {
    accessToken?: string;
    isLoggedin: boolean;
    login: Function;
    logout: Function;
    tokenData?: AccessTokenData;
    updateUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    user?: User;
    userFetchStatus: FetchStatus;
}

interface RefreshResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

type FetchStatus = "idle" | "pending" | "resolved" | "rejected";

export const AuthContext = React.createContext((null as unknown) as AuthContext);


const AUTH_TOKEN = "AUTH_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";

const AuthContextProvider: React.FC = ({children}) => {
    const [tokenData, setTokenData] = useState<AccessTokenData>();
    const [user, updateUser] = useState<User>();
    const [userFetchStatus, setUserFetchStatus] = useState<FetchStatus>("idle");
    const [accessToken, setAccessToken] = useState<string>();

    const logout = (message?: { type: "info" | "error"; text: string }) => {
        setTokenData(undefined);
        updateUser(undefined);
        window.sessionStorage.removeItem(AUTH_TOKEN);
        window.localStorage.removeItem(REFRESH_TOKEN);

        const s = new URLSearchParams();

        if (message) {
            s.append("a", "logout");
            s.append("t", message.type);
            s.append("msg", message.text);
        }

        window.location.href = "/?" + s.toString();
    };

    const login = (accessToken: string, refreshToken: string, user?: User) => {
        window.sessionStorage.setItem(AUTH_TOKEN, accessToken);
        window.localStorage.setItem(REFRESH_TOKEN, refreshToken);

        console.log(user)

        setAccessToken(accessToken);

        const token = jwt.decode(accessToken) as AccessTokenData;
        setTokenData(token);

        if (user) {
            updateUser(user);
            setUserFetchStatus("resolved");
        } else {
            setUserFetchStatus("pending");
            httpGet<User>("users/me")
                .then((d) => {
                    updateUser(d);
                    setUserFetchStatus("resolved");
                })
                .catch((e) => {
                    setUserFetchStatus("rejected");
                });
        }
    };

    const isLoggedin = useMemo(() => (tokenData && tokenData.exp > Date.now() / 1000) || false, [tokenData]);

    useEffect(() => {
        if (tokenData && !isLoggedin) {
            logout();
        }
    }, [tokenData, isLoggedin]);

    // check for tokens on startup
    useEffect(() => {
        const accessTokenStr = window.sessionStorage.getItem(AUTH_TOKEN);
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

        if (accessTokenStr) {
            let accessToken = jwt.decode(accessTokenStr) as AccessTokenData;

            // try to refresh token if it is expired or will expire within 5 minutes
            if (accessToken.exp < Date.now() / 1000 - 300 || !refreshToken) {
                setUserFetchStatus("pending");
                httpPost<RefreshResponse>(
                    "auth/refresh",
                    JSON.stringify({
                        refreshToken,
                    })
                )
                    .then((d) => {
                        setUserFetchStatus("resolved");
                        login(d.accessToken, d.refreshToken, d.user);
                    })
                    .catch((e) => {
                        setUserFetchStatus("rejected");
                        logout();
                    });
            }

            if (accessToken) {
                login(accessTokenStr, refreshToken!);
            }
        } else if (refreshToken) {
            setUserFetchStatus("pending");
            httpPost<RefreshResponse>(
                "auth/refresh",
                JSON.stringify({
                    refreshToken,
                })
            )
                .then((d) => {
                    setUserFetchStatus("resolved");
                    login(d.accessToken, d.refreshToken, d.user);
                })
                .catch((e) => {
                    setUserFetchStatus("rejected");
                    logout();
                });
        }
    }, []);

    const context = {
        accessToken,
        isLoggedin,
        login,
        logout,
        tokenData,
        updateUser,
        user,
        userFetchStatus,
    };

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export {AuthContextProvider};
